import { useEffect } from 'react';

import loadable from '@loadable/component';

import BpkImage, {
  withLazyLoading,
  BORDER_RADIUS_STYLES,
} from '@skyscanner/backpack-web/bpk-component-image';
import BpkSectionHeader from '@skyscanner/backpack-web/bpk-component-section-header';

import {
  ACTION_TYPE,
  CAR_HIRE_SEO_EVENT_COMPONENT,
  COMPONENT_ACTION,
  COMPONENT_NAME,
  LOAD_STATUS,
  PRODUCT_GROUP,
} from '../../constants';
import logMiniEventHelper from '../../mini-event/logMiniEventHelper';
import { setCarHireSeoEventValue } from '../../sessionStorage';

import type {
  ProviderInfo,
  ProviderLogosProps,
} from 'common-types/types/ProviderLogos';
import type { Vertical } from 'common-types/types/Vertical';

import STYLES from './ProviderLogos.module.scss';

const IntersectionObserverWrapper = loadable(
  () => import('../IntersectionObserverWrapper/IntersectionObserverWrapper'),
);

const documentIfExists = typeof window !== 'undefined' ? document : null;
const LazyLoadImage = withLazyLoading(BpkImage, documentIfExists);

const handleClick = (vertical: Vertical, props: ProviderInfo) => {
  if (vertical === PRODUCT_GROUP.CAR_HIRE) {
    logMiniEventHelper({
      action_type: ACTION_TYPE.COMPONENT_CLICKED,
      component_name: COMPONENT_NAME.PROVIDER_LOGOS,
      component_action: COMPONENT_ACTION.PROVIDER_LOGOS.SUPPLIER_LOGO_CLICKED,
      supplier: {
        id: Number(props.id),
        name: props.providerName,
      },
    });
    // Write source_page.source_component to session storage after component was clicked.
    // This source_component is used on dayView page to log a Mini-Event,
    // that help analyzing the enrichment of Landing page components.
    // https://confluence.skyscannertools.net/display/CHS/Conversion+Rate+%3A+From+Landing+Page+To+Search+Result+Page
    setCarHireSeoEventValue(
      'source_component',
      CAR_HIRE_SEO_EVENT_COMPONENT.PROVIDER_LOGOS,
      'source_page',
    );
  }
};

const ProviderLogos = ({
  headerText,
  providerInfo,
  vertical,
}: ProviderLogosProps) => {
  useEffect(() => {
    logMiniEventHelper({
      action_type: ACTION_TYPE.COMPONENT_LOADED,
      component_name: COMPONENT_NAME.PROVIDER_LOGOS,
      load_status:
        !headerText || !providerInfo?.length
          ? LOAD_STATUS.INVALID
          : LOAD_STATUS.LOADED,
    });
  }, [headerText, providerInfo]);

  if (!headerText || !providerInfo?.length) {
    return null;
  }

  return (
    <div className={STYLES.ProviderLogos}>
      <div className={STYLES.ProviderLogos__header}>
        <BpkSectionHeader title={headerText} />
      </div>

      <div className={STYLES.logos}>
        {providerInfo.map((provider) => {
          const image = (
            <LazyLoadImage
              altText={provider.providerName}
              className={STYLES.ProviderLogos__providerLogoImage}
              src={provider.src}
              aspectRatio={120 / 60}
              borderRadiusStyle={BORDER_RADIUS_STYLES.sm}
            />
          );

          return (
            <div key={provider.providerName}>
              {provider.link ? (
                <a
                  aria-label={provider.providerName}
                  href={provider.link}
                  onClick={() => handleClick(vertical, provider)}
                >
                  {image}
                </a>
              ) : (
                image
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default (props: ProviderLogosProps) => (
  <IntersectionObserverWrapper
    onElementSeen={() =>
      logMiniEventHelper({
        action_type: ACTION_TYPE.COMPONENT_VISIBLE,
        component_name: COMPONENT_NAME.PROVIDER_LOGOS,
      })
    }
  >
    <ProviderLogos {...props} />
  </IntersectionObserverWrapper>
);
